import React from "react";
import "../../components/customTable.css";
import { Typography } from "@mui/material";
import editIcon from "../../assets/svgs/Frame772543656.svg";
import deleteIcon from "../../assets/svgs/Delete_frame.svg";
import {
  setDeleteSubAdminModal,
  setEditStatusModal,
  setSingleSubAdminDetails,
} from "../../store/Gift4dayReducer";
import { useAppDispatch } from "../../hooks";
interface TableColumn {
  id: string;
  label: string;
  sortable?: boolean;
}

interface DynamicTableProps {
  columns: TableColumn[];
  data: any;
  title?: string;
  callback?: any;
  statusCallback?: () => void;
}
const SubAdminsTable: React.FC<DynamicTableProps> = ({
  columns,
  data,
  title,
  callback,
  statusCallback,
}) => {
  const dispatch = useAppDispatch();
  console.log(data, "gift4day users");
  return (
    <table>
      <thead>
        <tr style={{ background: "white" }}>
          {columns.map((column) => (
            <th key={column.id}>
              <Typography fontSize={14} textAlign={column.id == "status" ? "center" : "left"}>{column.label}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="tableBody">
        {data.map((row: any, rowIndex: any) => (
          <tr key={rowIndex}>
            {columns.map((column) => (
              <td
                key={column.id}
                style={{
                  textAlign: "left",
                }}
              >
                {title === "subAdmins" && column.id === "status" ? (
                  <Typography
                    textAlign={"center"}
                    fontSize={12}
                    padding="4px"
                    borderRadius={3}
                    bgcolor={
                      row[column.id] === "Active"
                        ? "rgba(230, 255, 233, 1)"
                        : "rgba(255, 74, 74, 0.08)"
                    }
                    color={
                      row[column.id] === "Active"
                        ? "rgba(0, 171, 17, 1)"
                        : "rgba(255, 74, 74, 1)"
                    }
                    onClick={() =>
                      dispatch(setEditStatusModal({ isOpen: true, ...row }))
                    }
                    style={{ cursor:'pointer' }}
                  >
                    {row[column.id]}
                  </Typography>
                ) : column.id === "first_name" ? (
                  <div
                    onClick={() => {
                      dispatch(
                        setSingleSubAdminDetails({
                          isOpen: true,
                          isEdit: true,
                          ...row,
                        })
                      );
                    }}
                    style={{
                      textDecoration: "none",
                      color: "rgba(108, 62, 185, 1)",
                      cursor: "pointer",
                    }}
                  >
                    <Typography fontSize={13}>{row[column.id]}</Typography>
                  </div>
                ) : column.id === "delete" ? (
                  <div
                    onClick={() =>
                      dispatch(
                        setDeleteSubAdminModal({ isOpen: true, id: row.id })
                      )
                    }
                  >
                    <img src={deleteIcon} style={{ cursor: "pointer" }} />
                  </div>
                ) : (
                  <Typography fontSize={13}>{row[column.id]}</Typography>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SubAdminsTable;
